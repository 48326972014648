import resizeImage from '#/utils/resize-image';
import { auth, images, user } from '@cardano-calendar/sdk';
import { defineStore } from 'pinia';
import { useToast } from 'vue-toastification';

export const UserStoreStatus = Object.freeze({
  Unknown: 0,
  Loading: 1,
  Unauthorized: 2,
  Authorized: 3,
  Error: 4,
});

export const useUserStoreUnsafe = defineStore('user', {
  state: () => ({
    /** Reflect current state of the store. @see {@link UserStoreStatus} */
    _status: UserStoreStatus.Unknown,
    /** @type {Promise<boolean> | null} */
    _loadingPromise: null,
    /**
     * Contains error object in {@link UserStoreStatus.Error} status
     * @type {Error | null}
     */
    _error: null,

    id: NaN,
    email: '',
    fullName: '',
    /** URL to an avatar image @type {string | null} */
    avatarUrl: null,
    bio: '',
    /** @type {Array<CardanoSocial>} */
    links: [
      // { type: 'twitter', url: 'example_username' },
    ],
    /** @type {Array<CardanoKeyword>} */
    tags: [],

    /**
     * Does user has access to the admin dashboard
     * @type {boolean}
     */
    isAdmin: false,
    /** Does user verified the email */
    isEmailVerified: false,
    isGoogleCalendarConnected: false,
  }),
  getters: {
    isInitialized: ({ _status }) => _status > UserStoreStatus.Loading,
    isUnknown: ({ _status }) => _status === UserStoreStatus.Unknown,
    isLoading: ({ _status }) => _status === UserStoreStatus.Loading,
    isAuthorized: ({ _status }) => _status === UserStoreStatus.Authorized,
    isUnauthorized: ({ _status }) => _status === UserStoreStatus.Unauthorized,
    isError: ({ _status }) => _status === UserStoreStatus.Error,
  },
  actions: {
    /** @param {CardanoUser} userData  */
    setFromAPI(userData) {
      this.id = userData.id;
      this.email = userData.email;
      this.fullName = userData.fullName;
      this.avatarUrl = userData.avatar;
      this.bio = userData.bio;
      this.isEmailVerified = userData.isVerified;
      this.isGoogleCalendarConnected = userData.isGoogleCalendarConnected;
      this.isAdmin = userData.isAdmin;

      this.links = userData.links;
      this.tags = userData.tags ?? [];
    },

    async init() {
      this.$reset();
      if (await auth.refresh()) {
        try {
          const userData = await user.getUser();
          this.setFromAPI(userData);
          this._status = UserStoreStatus.Authorized;
          return true;
        } catch (error) {
          this._status = UserStoreStatus.Error;
          this._error = error;
          console.error('Cannot get user data', error);
          return false;
        }
      } else {
        this._status = UserStoreStatus.Unauthorized;
        return false;
      }
    },

    /**
     * Initialize the store if it wasn't initialized before.
     * @returns Returns `true` if initialization is successful or `false` if an error occurred.
     */
    async safeInit() {
      if (this._status === UserStoreStatus.Authorized) return true;
      if (
        this._status === UserStoreStatus.Unauthorized ||
        this._status === UserStoreStatus.Error
      )
        return false;

      if (this._loadingPromise) return Promise.resolve(this._loadingPromise);

      this._status = UserStoreStatus.Loading;
      this._loadingPromise = Promise.resolve(this.init()).finally(
        () => (this._loadingPromise = null),
      );
      return this._loadingPromise;
    },

    /**
     * Register a user and initialize the store.
     * @param {string} email
     * @param {string} password
     * @param {string} username
     * @returns Returns `true` if registration is successful otherwise `false`.
     */
    async register(email, password, username) {
      this.$reset();
      try {
        await auth.register(email, password, username);
      } catch (error) {
        this._status = UserStoreStatus.Error;
        this._error = error;
        return false;
      }
      return this.login(email, password);
    },

    /**
     * Login by credentials and initialize the store.
     * @param {string} email
     * @param {string} password
     * @returns Returns `true` if login is successful otherwise `false`.
     */
    async login(email, password) {
      this.$reset();
      try {
        const { user, error } = await auth.login(email, password);
        if (error) {
          this._status = UserStoreStatus.Error;
          this._error = error;
          return false;
        }
        this.setFromAPI(user);
        return true;
      } catch (error) {
        this._status = UserStoreStatus.Error;
        this._error = error;
        return false;
      }
    },

    async logout() {
      await auth.logout();
      this.$reset();
      this._status = UserStoreStatus.Unauthorized;
    },

    /**
     * Update user data and reflect changes in the store.
     * @param {{ email: string; fullName: string; bio: string; avatar: File | string | null; links: Array<CardanoSocial>; tags: Array<number>}} data
     */
    async update(data) {
      const toast = useToast();
      let cleanupAvatar = false;

      if (data.avatar instanceof File) {
        try {
          const targetSize = 192;
          data.avatar = await resizeImage(
            data.avatar,
            `avatar-${this.id}-${targetSize}`,
            targetSize,
          );
        } catch (error) {
          console.error(error);
        }

        try {
          data.avatar = await images.upload(data.avatar);
          cleanupAvatar = true;
        } catch (e) {
          toast.error('Cannot upload the avatar');
          console.error('Avatar uploading failed', e);
          return false;
        }
      } else if (data.avatar === null) {
        data.avatar = null;
      }

      try {
        const oldAvatarURL = this.avatarUrl;
        const updatedData = await user.updateUser(data);
        this.setFromAPI(updatedData);

        if (data.avatar === null && oldAvatarURL) {
          images
            .remove([oldAvatarURL])
            .catch((error) =>
              console.error('Old avatar cleanup failed', error),
            );
        }
        return true;
      } catch (e) {
        toast.error('Failed to update user info');
        console.error('Failed to update user info', e);

        // Delete uploaded avatar
        if (cleanupAvatar) {
          console.debug('Cleanup avatar');
          images
            .remove([data.avatar])
            .catch((error) => console.error('Avatar cleanup failed', error));
        }
        return false;
      }
    },
  },
});

export function useUserStore() {
  const store = useUserStoreUnsafe();
  store.safeInit();
  return store;
}
