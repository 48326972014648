import axios from 'axios';
import useReportErrorInterceptor from '../utils/report-error';

const instance = axios.create({
  baseURL: import.meta.env.VITE_API_URI + '/user',
  headers: { Accept: 'application/json' },
  withCredentials: true,
});
useReportErrorInterceptor(instance);

/**
 * @returns {Promise<CardanoUser>}
 */
export async function getUser() {
  const response = await instance.get('');
  return response.data;
}
/**
 * @param {CardanoUserInfo} data
 * @returns {Promise<CardanoUser>}
 */
export async function updateUser(data) {
  const response = await instance.post('', data);
  return response.data;
}

/**
 * @param {Array<number>} keyword_ids
 */
export async function setKeywords(keyword_ids) {
  const response = await instance.post('/tags', keyword_ids);
  return response.data;
}

export async function requestEmailVerification() {
  try {
    const response = await instance.post('/email/verification/request');
    return response.data;
  } catch (error) {
    throwRealErrorMessage(error)
  }
}

/**
 * @param {string} nonce base64url encoded string 
 */
export async function submitEmailVerification(nonce) {
  try {
    const response = await instance.post('/email/verification/submit', {
      payload: nonce,
    });
    return response.data;
  } catch (error) {
    throwRealErrorMessage(error)
  }
}

/**
 * @param {string} email new email address
 */
export async function requestEmailChange(email) {
  try {
    const response = await instance.post('/email/change/request', {
      email
    });
    return response.data;
  } catch (error) {
    throwRealErrorMessage(error)
  }
}

/**
 * @param {string} nonce base64url encoded string 
 */
export async function submitEmailChange(nonce) {
  try {
    const response = await instance.post('/email/change/submit', {
      payload: nonce,
    });
    return response.data;
  } catch (error) {
    throwRealErrorMessage(error)
  }
}

function throwRealErrorMessage(error) {
  let message = error.message;
  if(error.response) {
    message = error.response.data.message
  }
  throw new Error(message)
}